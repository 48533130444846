import { WebsiteDetail } from "@/websites/actions/fetchWebsite";
import type { Website } from "@/websites/actions/fetchWebsites";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";

const Screengrab = ({
  website,
  inverse = false,
  href = null,
  priority = false,
  slideshowContext = false,
}: {
  website: Website | WebsiteDetail;
  inverse?: boolean;
  href?: string | null;
  priority?: boolean;
  slideshowContext?: boolean;
}) => {
  if (!website) {
    return null;
  }

  // The image will be displayed 100vw until 1024, then 2/3 of the viewport
  const sizes = "(min-width: 1024px) 66.666vw, 100vw";

  const _Wrapper = ({
    children,
    className,
  }: {
    children: React.ReactElement;
    className: string;
  }) => {
    if (!!href) {
      return (
        <Link href={href} target="_blank" className={className}>
          {children}
        </Link>
      );
    }

    return <div className={className}>{children}</div>;
  };

  // Determine the image src
  const imageSrc = !!website.versions?.[0]?.imageData
    ? `/${website.versions[0].imageData?.id}`
    : null;

  return (
    <_Wrapper
      className={clsx("block w-full overflow-hidden rounded", {
        "bg-zinc-100": !inverse,
        "bg-white": inverse,
        "p-6": slideshowContext,
        "p-8 lg:p-6": !slideshowContext,
      })}
    >
      <div
        className={clsx("relative aspect-[164/95] overflow-hidden rounded-sm")}
      >
        {!!imageSrc && (
          <Image
            src={imageSrc}
            alt={website.title || "Screengrab"}
            fill
            className={clsx("object-cover object-top")}
            sizes={sizes}
            priority={priority}
            draggable={false}
          />
        )}
      </div>
    </_Wrapper>
  );
};

export default Screengrab;
