"use client";

import Screengrab from "@/components/Screengrab";
import type { Website } from "@/websites/actions/fetchWebsites";
import WebsiteCaption from "@/websites/components/WebsiteCaption";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";
import { EmblaCarouselType, EmblaOptionsType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import Link from "next/link";
import { useCallback, useEffect } from "react";

dayjs.extend(relative);

const options: EmblaOptionsType = {
  align: (snapSize: number, index: number) => {
    return Math.round(index / snapSize) * snapSize;
  },
  skipSnaps: true,
};

const Slideshow = ({
  websites = [],
  onSlideChange = (index: number) => null,
}: {
  websites: Website[];
  onSlideChange?: (index: number) => void;
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const logSlidesInView = useCallback(
    (emblaApi: EmblaCarouselType) => {
      onSlideChange(emblaApi.selectedScrollSnap());
    },
    [onSlideChange]
  );

  useEffect(() => {
    if (emblaApi) emblaApi.on("select", logSlidesInView);
  }, [emblaApi, logSlidesInView]);

  return (
    <>
      <div
        ref={emblaRef}
        className={clsx("px-8 lg:pl-[calc((100%/3)+2rem)] overflow-hidden")}
      >
        <div className={clsx("flex space-x-8")}>
          {websites.map((website, _index) => {
            return (
              <div
                key={website.id}
                className={clsx(
                  "flex-[0_0_calc(100vw-8rem)] lg:flex-[0_0_75%] flex flex-col"
                )}
              >
                <Link href={`/websites/${website.id}-${website.slug}`}>
                  <Screengrab
                    priority={_index === 0}
                    website={website}
                    inverse
                    slideshowContext
                  />
                </Link>
                <WebsiteCaption website={website} />
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={clsx("absolute bottom-32 right-8 space-x-2 hidden lg:flex")}
      >
        <button
          onClick={() => emblaApi?.scrollPrev()}
          className={clsx(
            "flex h-8 w-8 items-center justify-center rounded-full transition-colors hover:bg-zinc-50 bg-white"
          )}
        >
          <ChevronLeftIcon className={clsx("w-4 h-4")} />
        </button>
        <button
          onClick={() => emblaApi?.scrollNext()}
          className={clsx(
            "flex h-8 w-8 items-center justify-center rounded-full transition-colors hover:bg-zinc-50 bg-white"
          )}
        >
          <ChevronRightIcon className={clsx("w-4 h-4")} />
        </button>
      </div>
    </>
  );
};

export default Slideshow;
