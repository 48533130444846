"use client";

import Slideshow from "@/components/Slideshow";
import type { Website } from "@/websites/actions/fetchWebsites";
import clsx from "clsx";
import { useEffect, useState } from "react";

const DURATION = 0.5;

const HeroMobile = ({ websites }: { websites: Website[] }) => {
	const [showStandfirst, setShowStandfirst] = useState(true);
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		setIsMounted(true);
	}, []);

	return (
		<div className={clsx("pt-header relative block lg:hidden")}>
			<div className={clsx("mb:pt-6 px-8 py-4 pb-8 md:pb-4")}>
				<h1 className={clsx("text-center text-2xl md:text-3xl")}>
					A showcase of the web’s
					<br />
					finest design + talent
				</h1>
			</div>
			<div className={clsx("bg-zinc-100 py-8")}>
				<Slideshow websites={websites} />
			</div>
		</div>
	);
};

export default HeroMobile;
