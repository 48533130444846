const getCategoryQueryString = (
  paramName = "category",
  params: string[] = [],
  value: string,
  action: "add" | "remove"
) => {
  let newValues = [...params]; // Copy the existing values to avoid direct mutation

  if (action === "add") {
    // Add the new value only if it's not already present
    if (!newValues.includes(value)) {
      newValues.push(value);
    }
  } else if (action === "remove") {
    // Filter out the value to be removed
    newValues = newValues.filter((val) => val !== value);
  }

  // Construct the query string manually
  if (newValues.length > 0) {
    // Join all values with '&' and prepend each with the parameter name
    return newValues
      .map(
        (val) => `${encodeURIComponent(paramName)}=${encodeURIComponent(val)}`
      )
      .join("&");
  } else {
    // Return an empty string if no values are left
    return "";
  }
};

export default getCategoryQueryString;
