const filterContexts = [
  {
    id: 1,
    key: "style",
    name: "Styles",
  },
  {
    id: 2,
    key: "type",
    name: "Types",
  },
  {
    id: 3,
    key: "subject",
    name: "Subjects",
  },
  {
    id: 4,
    key: "platform",
    name: "Platforms",
  },
];

export default filterContexts;
