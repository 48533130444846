'use client';

import WebflowCircle from '@/components/WebflowCircle';
import { useWebsitesContext } from '@/contexts/WebsitesContext';
import filterContexts from '@/lib/filterContexts';
import getCategoryQueryString from '@/websites/lib/getCategoryQueryString';
import { ChevronDownIcon, ChevronUpIcon, XMarkIcon } from '@heroicons/react/24/outline';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import * as Tabs from '@radix-ui/react-tabs';
import clsx from 'clsx';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { Suspense, useMemo, useRef, useState } from 'react';

const previewCount = 14;

interface Category {
  slug: string;
  name: string;
  count: number;
  context: string;
}

const WebsiteFilterWrapper = ({ categories }: { categories: Category[] }) => {
  return (
    <Suspense>
      <WebsiteFilter categories={categories} />
    </Suspense>
  );
};

const WebsiteFilter = ({ categories }: { categories: Category[] }) => {
  const { selectedContext, setSelectedContext, isFilterOpen, setIsFilterOpen } = useWebsitesContext();
  const searchParams = useSearchParams();
  const selectedCategorySlugs = searchParams.getAll('category');

  const selectedCategories = categories.filter((category) => selectedCategorySlugs.includes(category.slug));

  const popularCategoriesRef = useRef<Category[]>([]);

  const popularCategories = useMemo(() => {
    if (popularCategoriesRef.current.length === 0) {
      popularCategoriesRef.current = [...categories]
        .sort((a, b) => {
          const countDiff = b.count - a.count;
          if (countDiff !== 0) return countDiff;
          return a.name.localeCompare(b.name);
        })
        .slice(0, previewCount);
    }

    // Update counts while maintaining the original order
    return popularCategoriesRef.current.map((popularCategory) => {
      const updatedCategory = categories.find((c) => c.slug === popularCategory.slug);
      return updatedCategory || popularCategory;
    });
  }, [categories]);

  const categoriesByContext = useMemo(() => {
    const result: Record<string, Category[]> = {};
    filterContexts.forEach((context) => {
      result[context.key] = categories.filter((category) => category.context === context.key);
    });
    return result;
  }, [categories]);

  return (
    <div className={clsx('flex justify-end')}>
      <div className={clsx('w-full lg:w-2/3 px-8 ')}>
        <Tabs.Root
          orientation="horizontal"
          defaultValue={selectedContext}
          className={clsx('space-y-4 lg:space-y-6 overflow-hidden max-w-[100ch]')}
          onValueChange={setSelectedContext}
          value={selectedContext}
        >
          <div className={clsx('flex justify-between items-center')}>
            <ScrollArea.Root className={clsx('w-full overflow-hidden')}>
              <ScrollArea.Viewport className={clsx('w-full overflow-x-scroll')}>
                <Tabs.List aria-label="" className={clsx('filter-tabs-list group/tabs-list')}>
                  <Tabs.Trigger
                    value="popular"
                    className={clsx('filter-tabs-trigger ', {
                      'text-zinc-400': selectedContext !== 'popular' && isFilterOpen,
                      'filter-tabs-trigger-active': selectedContext === 'popular' && isFilterOpen,
                    })}
                    onClick={() => setIsFilterOpen(true)}
                  >
                    Popular
                  </Tabs.Trigger>
                  {filterContexts.map((context) => (
                    <Tabs.Trigger
                      key={context.key}
                      value={context.key}
                      className={clsx('filter-tabs-trigger', {
                        'text-zinc-400': selectedContext !== context.key && isFilterOpen,
                        'filter-tabs-trigger-active': selectedContext === context.key && isFilterOpen,
                      })}
                      onClick={() => setIsFilterOpen(true)}
                    >
                      {context.name}
                    </Tabs.Trigger>
                  ))}
                </Tabs.List>
              </ScrollArea.Viewport>
              <ScrollArea.Scrollbar orientation="horizontal" className={clsx('hidden')}>
                <ScrollArea.Thumb className={clsx('hidden')} />
              </ScrollArea.Scrollbar>
            </ScrollArea.Root>

            <div className={clsx('ml-4 flex-shrink-0')}>
              <button
                className={clsx('size-8 bg-zinc-100 rounded-full flex items-center justify-center')}
                onClick={() => setIsFilterOpen((prev) => !prev)}
                aria-label="Toggle filter"
              >
                <div>
                  {isFilterOpen ? (
                    <ChevronUpIcon className={clsx('size-4')} />
                  ) : (
                    <ChevronDownIcon className={clsx('size-4')} />
                  )}
                </div>
              </button>
            </div>
          </div>

          {isFilterOpen && (
            <>
              <TabContent
                value="popular"
                categories={popularCategories}
                selectedCategorySlugs={selectedCategorySlugs}
              />
              {filterContexts.map((context) => (
                <TabContent
                  key={context.key}
                  value={context.key}
                  categories={categoriesByContext[context.key] ?? []}
                  selectedCategorySlugs={selectedCategorySlugs}
                />
              ))}
            </>
          )}
        </Tabs.Root>

        {selectedCategories.length > 0 && (
          <div className={clsx('flex pt-4 flex-wrap')}>
            {selectedCategories.map((category) => (
              <SelectedCategoryLink
                key={category.slug}
                category={category}
                selectedCategorySlugs={selectedCategorySlugs}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const TabContent = ({
  value,
  categories,
  selectedCategorySlugs,
}: {
  value: string;
  categories: Category[];
  selectedCategorySlugs: string[];
}) => {
  return (
    <Tabs.Content value={value} className={clsx('filter-tabs-content group/tab')}>
      <CategoryList categories={categories} selectedCategorySlugs={selectedCategorySlugs} />
    </Tabs.Content>
  );
};

const CategoryList = ({
  categories,
  selectedCategorySlugs,
}: {
  categories: Category[];
  selectedCategorySlugs: string[];
}) => {
  const [showAll, setShowAll] = useState(false);

  const displayCategories = useMemo(() => {
    return showAll ? categories : categories.slice(0, previewCount);
  }, [categories, showAll]);

  return (
    <>
      {displayCategories.map((category) => (
        <CategoryLink key={category.slug} category={category} selectedCategorySlugs={selectedCategorySlugs} />
      ))}
      {categories.length > previewCount && !showAll && (
        <button
          className={clsx(
            'filter-tabs-content-link transition ease-out text-zinc-500 underline underline-offset-4 decoration-1 decoration-zinc-300 hover:text-zinc-950',
          )}
          onClick={() => setShowAll(true)}
        >
          Show more
        </button>
      )}
      {showAll && (
        <button
          className={clsx(
            'filter-tabs-content-link transition ease-out text-zinc-500 underline underline-offset-4 decoration-1 decoration-zinc-300 hover:text-zinc-950',
          )}
          onClick={() => setShowAll(false)}
        >
          Show fewer
        </button>
      )}
    </>
  );
};

const CategoryLink = ({ category, selectedCategorySlugs }: { category: Category; selectedCategorySlugs: string[] }) => {
  const disabled = category.count === 0;
  const action = selectedCategorySlugs.includes(category.slug) ? 'remove' : 'add';

  const newQueryString = getCategoryQueryString('category', selectedCategorySlugs, category.slug, action);

  const href = `/websites?${newQueryString}`;
  const isWebflow = category.slug === 'webflow';

  return (
    <Link
      href={href}
      className={clsx('filter-tabs-content-link transition ease-out group relative', {
        'hover:text-zinc-500': !selectedCategorySlugs.includes(category.slug) && !disabled,
        'filter-tabs-content-link--selected selected': selectedCategorySlugs.includes(category.slug),
        'filter-tabs-content-link--disabled': disabled,
      })}
      aria-disabled={disabled}
      scroll={false}
    >
      <div className={clsx('', { webflow: isWebflow })}>
        <div className={clsx('flex space-x-2')}>
          <div
            className={clsx('filter-tabs-content-link-inner', {
              'filter-tabs-content-link-inner-selected': selectedCategorySlugs.includes(category.slug),
            })}
          >
            {category.name}
          </div>
          {isWebflow && <WebflowCircle />}
        </div>
      </div>
      <div
        className={clsx(
          'absolute flex inset-y-0 pr-12 pl-2 z-10 right-0 group-hover:opacity-100 items-center w-auto translate-x-full bg-gradient-to-r via-white to-transparent from-white pointer-events-none opacity-0 transition ease-out',
        )}
      >
        <div className={clsx('flex items-center justify-center text-base text-zinc-950 bg-zinc-100 rounded-full px-2')}>
          {category.count.toLocaleString('en', { useGrouping: true })}
        </div>
      </div>
    </Link>
  );
};

const SelectedCategoryLink = ({
  category,
  selectedCategorySlugs,
}: {
  category: Category;
  selectedCategorySlugs: string[];
}) => {
  const queryString = getCategoryQueryString('category', selectedCategorySlugs, category.slug, 'remove');

  const href = !!queryString ? `/websites?${queryString}` : `/websites?`;

  return (
    <Link href={href} className={clsx('flex items-center mt-4 space-x-1 mr-4 hover:text-zinc-500 transition ease-out')}>
      <div className={clsx('whitespace-nowrap')}>{category.name}</div>
      <div>
        <XMarkIcon className={clsx('w-4 h-4')} />
      </div>
    </Link>
  );
};

export default WebsiteFilterWrapper;
